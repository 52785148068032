@tailwind base;
@tailwind components;
@tailwind utilities;

body {
 /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /*overflow-y: hidden;*/
  font-family: Roboto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Global CSS */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #d3d3d3; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #cccbcb; /* Color on hover */
}

/* In your App.css or fonts.css */
/*@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('Roboto-Regular'),
       url('../fonts/Roboto-Regular.ttf') format('Roboto-Regular');
  font-weight: normal;
  font-style: normal;
}*/

/*
* {
  font-family: 'Roboto', sans-serif;
}
*/
