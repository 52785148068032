// Profile.scss
.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
  }
  
  .profile-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    min-height: 300px;
    text-align: center;
  
    // Nested styles for the profile picture and details
    .profile-picture {
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 15px;
      }
    }
  
    .profile-details {
      h2 {
        margin: 0;
        font-size: 1.5rem;
        color: #333;
      }
  
      p {
        margin: 0;
        font-size: 1rem;
        color: #777;
      }
    }
  }
  
  // Responsive Styles
  @media (max-width: 600px) {
    .profile-card {
      max-width: 90%;
      padding: 15px;
    }
  
    .profile-picture {
      img {
        width: 80px;
        height: 80px;
      }
    }
  
    .profile-details {
      h2 {
        font-size: 1.2rem;
      }
  
      p {
        font-size: 0.9rem;
      }
    }
  }
  