.side-menu-logo-small {
    width: 45px;
    height: 30px;
    margin: 8px auto;
  }
  
  .side-menu-logo {
    height: 60px;
  }
  
  .side-menu-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #1976d2;
    color: white;
    font-weight: 500;
  }