/* src/fonts/fonts.css */
@font-face {
  font-family: 'Roboto';
  src: local("RobotoRegular"),url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local("RobotoRegular"),url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local("RobotoRegular"),url('../fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: local("RobotoRegular"), url('../fonts/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: local("RobotoRegular"), url('../fonts/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: local("RobotoRegular"), url('../fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: local("RobotoRegular"), url('../fonts/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: local("RobotoRegular"), url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: local("RobotoRegular"), url('../fonts/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: local("RobotoRegular"), url('../fonts/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: local("RobotoRegular"), url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: local("RobotoRegular"), url('../fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  
  