.login{
   
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.login-container{
    width: 400px;
    height: 500px;
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.logos {
    display: flex;
    justify-content: center;
    gap: 20px;
}
.logo img {
    width: 100px; /* Adjust the size of the logos as needed */
    height: auto;
}
.logo-okta{
   
    margin-top: 10px;
}
.logo-okta img{
   
    width: 80px;
}

.button-container {
    margin-top: 20px;
}
.description {
    font-size: 16px;    
    padding: 15px; /* Added padding around the description */
    background-color: #f9f9f9; /* Optional: Adds a background color to the description box */
    border-radius: 8px; /* Optional: Adds rounded corners to the description box */
    line-height: 1.6;
}
.button-container button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #0A7EC2;
    color: white;
    border: none;
    border-radius: 5px;
}
.button-container button:hover {
    background-color: #0A7EC2;
}